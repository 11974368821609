import getConfig from 'next/config';
import { envExperienceKey, envTechStackKey, envWorkKey } from '../common';

export const environmentVariables = {
  isDev: process.env.NEXT_PUBLIC_IS_DEV,
  isTechLinkVisible: process.env.NEXT_PUBLIC_TECH_LINK_IS_VISIBLE,
  aboutMeTextBefore: process.env.NEXT_PUBLIC_ABOUT_ME.split("INDIA")[0],
  aboutMeTextAfter: process.env.NEXT_PUBLIC_ABOUT_ME.split("INDIA")[1],
  design: process.env.NEXT_PUBLIC_DESIGN,
  designDescription: process.env.NEXT_PUBLIC_DESIGN_DESCRIPTION,
  figma: process.env.NEXT_PUBLIC_FIGMA,
  illustrator: process.env.NEXT_PUBLIC_ILLUSTRATOR,
  webflow: process.env.NEXT_PUBLIC_WEBFLOW,
  resume: process.env.NEXT_PUBLIC_RESUME,
  projects: process.env.NEXT_PUBLIC_PROJECTS,
  userName: process.env.NEXT_PUBLIC_USERNAME,
  fullUserName: process.env.NEXT_PUBLIC_USERNAME_FULL,
  work: process.env.NEXT_PUBLIC_WORK,
  about: process.env.NEXT_PUBLIC_ABOUT,
  tech: process.env.NEXT_PUBLIC_TECHNOLOGY,
  webDev: process.env.NEXT_PUBLIC_WEB_DEV,
  webDevDescription: process.env.NEXT_PUBLIC_WEB_DEV_DESCRIPTION,
  nextJs: process.env.NEXT_PUBLIC_NEXTJS,
  tailwind: process.env.NEXT_PUBLIC_TAILWIND,
  typescript: process.env.NEXT_PUBLIC_TYPESCRIPT,
  experience: process.env.NEXT_PUBLIC_EXPERIENCE,
  carrierStartYear: process.env.NEXT_PUBLIC_CARRIER_START_YEAR,
  yearsOfExperience: process.env.NEXT_PUBLIC_YEARS_OF_EXPERIENCE,
  yearsOfExperienceDescription: process.env.NEXT_PUBLIC_YEARS_OF_EXPERIENCE_DESCRIPTION,
  getInTouch: process.env.NEXT_PUBLIC_GET_IN_TOUCH,
  getInTouchDescription: process.env.NEXT_PUBLIC_GET_IN_TOUCH_DESCRIPTION,
  works: process.env.NEXT_PUBLIC_WORKS,
  worksDescription: process.env.NEXT_PUBLIC_WORKS_DESCRIPTION,
  developer: process.env.NEXT_PUBLIC_DEVELOPER,
  designer: process.env.NEXT_PUBLIC_DESIGNER,
  weeb: process.env.NEXT_PUBLIC_WEEB,
  contact: process.env.NEXT_PUBLIC_CONTACT,
  gmail: process.env.NEXT_PUBLIC_GMAIL,
  userGmail: process.env.NEXT_PUBLIC_USER_GMAIL,
  linkedIn: process.env.NEXT_PUBLIC_LINKEDIN,
  instagram: process.env.NEXT_PUBLIC_INSTAGRAM,
  instagramAccount: process.env.NEXT_PUBLIC_INSTAGRAM_ACCOUNT,
  dattebayo: process.env.NEXT_PUBLIC_DATTEBAYO,
  develop: process.env.NEXT_PUBLIC_DEVELOP,
  anime: process.env.NEXT_PUBLIC_ANIME,
  psychology: process.env.NEXT_PUBLIC_PSYCHOLOGY,
  techStack: process.env.NEXT_PUBLIC_TECHHEADER_STACK,
  techStackDescription: process.env.NEXT_PUBLIC_TECHHEADER_DESCRIPTION,
  techTitle: process.env.NEXT_PUBLIC_TECHHEADER_TITLE,
  aboutTitle: process.env.NEXT_PUBLIC_ABOUT_TITLE,
  workTitle: process.env.NEXT_PUBLIC_WORK_TITLE,
  homeTitle: process.env.NEXT_PUBLIC_HOME_TITLE,
  shortInformation: process.env.NEXT_PUBLIC_SHORT_INFORMATION,
  contactTitle: process.env.NEXT_PUBLIC_CONTACT_TITLE,
  name: process.env.NEXT_PUBLIC_NAME,
  email: process.env.NEXT_PUBLIC_EMAIL,
  message: process.env.NEXT_PUBLIC_MESSAGE,
  submit: process.env.NEXT_PUBLIC_SUBMIT,
  githubRepo: process.env.NEXT_PUBLIC_GITHUB_REPO,
  thankYouTitle: process.env.NEXT_PUBLIC_THANK_YOU_TITLE,
  thankYou: process.env.NEXT_PUBLIC_THANK_YOU,
  formSubmissionReceived: process.env.NEXT_PUBLIC_FORM_SUBMISSION_RECEIVED,
  content: process.env.NEXT_PUBLIC_CONTENT,

  // RESUME
  resumeDesignation: process.env.NEXT_PUBLIC_RESUME_DESIGNATION,
  resumePersonalInfo: process.env.NEXT_PUBLIC_RESUME_PERSONAL_INFO,
  resumeSkills: process.env.NEXT_PUBLIC_RESUME_SKILLS,
  resumeExcellent: process.env.NEXT_PUBLIC_RESUME_EXCELLENT,
  resumeVeryGood: process.env.NEXT_PUBLIC_RESUME_VERY_GOOD,
  resumeAverage: process.env.NEXT_PUBLIC_RESUME_AVERAGE,
  resumeLeftSectionBackground: process.env.NEXT_PUBLIC_RESUME_LEFT_SECTION_BACKGROUND,
  resumeRoundedRectangleBarBackground: process.env.NEXT_PUBLIC_RESUME_ROUNDED_RECTANGLE_BAR_BACKGROUND,
  resumeRoundedRectangleProficiencyBarBackground: process.env.NEXT_PUBLIC_RESUME_ROUNDED_RECTANGLE_PROFICIENCY_BAR_BACKGROUND,
  resumeOtherSkill: process.env.NEXT_PUBLIC_RESUME_OTHER_SKILL,
  resumeLanguage: process.env.NEXT_PUBLIC_RESUME_LANGUAGE,
  resumeSocialPresence: process.env.NEXT_PUBLIC_RESUME_SOCIAL_PRESENCE,
  resumeProfessionalExperience: process.env.NEXT_PUBLIC_RESUME_PROFESSIONAL_EXPERIENCE,
  resumeEducation: process.env.NEXT_PUBLIC_RESUME_EDUCATION,

  techData: (() => {
    const { publicRuntimeConfig } = getConfig();
    let sequence;

    if (process.env.NEXT_PUBLIC_TECH_SEQ) {
      sequence = process.env.NEXT_PUBLIC_TECH_SEQ.split(',').map(Number);
    }

    const data = [];

    function getTechFromEnv(index) {
      const techData = {};

      for (const key of envTechStackKey) {
        const envKey = `NEXT_PUBLIC_TECH_${index}_${key}`;
        techData[key.toLowerCase()] = publicRuntimeConfig[envKey];
      }

      if (!techData.title) {
        return null;
      }

      return techData;
    }

    if (sequence) {
      sequence.forEach((id) => {
        const tech = getTechFromEnv(id);
        if (tech) {
          data.push(tech);
        }
      });
    } else {
      let index = 1;
      while (true) {
        const tech = getTechFromEnv(index);
        if (tech) {
          data.push(tech);
          index++;
        } else {
          break;
        }
      }
    }

    return data;
  })(),

  resumeSocialPresences: (() => {
    const socialPresences = {};
    let index = 1; // start at 1 because your env variables start at 1

    while (true) {
      const key = process.env[`NEXT_PUBLIC_RESUME_SOCIAL_PRESENCE_${index}`];
      const value = process.env[`NEXT_PUBLIC_RESUME_SOCIAL_PRESENCE_${index}_INFO`];

      if (!key || !value) {
        break;
      }

      socialPresences[`${key}:`] = value;
      index++;
    }

    return socialPresences;
  })(),

  resumeAboutInfos: (() => {
    const aboutInfos = [];
    let index = 1; // start at 1 because your env variables start at 1

    while (true) {
      const value = process.env[`NEXT_PUBLIC_RESUME_ABOUT_INFO_${index}`];

      if (!value) {
        break;
      }

      aboutInfos.push(value);
      index++;
    }

    return aboutInfos;
  })(),

  resumeLanguages: (() => {
    const languages = [];
    let index = 1; // start at 1 because your env variables start at 1

    while (true) {
      const language = process.env[`NEXT_PUBLIC_RESUME_LANGUAGE_${index}`];
      const proficiency = process.env[`NEXT_PUBLIC_RESUME_LANGUAGE_${index}_PROFICIENCY`];
      const label = process.env[`NEXT_PUBLIC_RESUME_LANGUAGE_${index}_LABEL`];

      if (!language || !proficiency || !label) {
        break;
      }

      languages.push({
        language,
        proficiency,
        label
      });
      index++;
    }

    return languages;
  })(),

  resumeOtherSkills: (() => {
    const skills = [];
    let index = 1; // start at 1 because your env variables start at 1

    while (true) {
      const skill = process.env[`NEXT_PUBLIC_RESUME_OTHER_SKILL_${index}`];

      if (!skill) {
        break;
      }

      skills.push(skill);
      index++;
    }

    return skills;
  })(),

  resumeSkillsList: (() => {
    const skillsList = [];
    let index = 1; // start at 1 because your env variables start at 1

    while (true) {
      const skill = process.env[`NEXT_PUBLIC_RESUME_SKILLS_${index}`];
      const proficiency = process.env[`NEXT_PUBLIC_RESUME_SKILLS_${index}_PROFICIENCY`];
      const label = process.env[`NEXT_PUBLIC_RESUME_SKILLS_${index}_LABEL`];

      if (!skill || !proficiency || !label) {
        break;
      }

      skillsList.push({
        skill,
        proficiency,
        label
      });
      index++;
    }

    return skillsList;
  })(),

  resumePersonalInfos: (() => {
    const personalInfos = {};
    let index = 1; // start at 1 as your env variables start at 1

    while (true) {
      const infoKey = process.env[`NEXT_PUBLIC_RESUME_PERSONAL_INFO_${index}`];
      const infoVal = process.env[`NEXT_PUBLIC_RESUME_PERSONAL_INFO_${index}_VAL`];

      if (!infoKey || !infoVal) {
        break;
      }

      personalInfos[`${infoKey}:`] = infoVal;
      index++;
    }

    return personalInfos;
  })(),

  getPageExperienceData: (() => {
    const experienceData = [];
    let index = 1; // starting from 1 as per your env format

    while (true) {
      const duration = process.env[`NEXT_PUBLIC_RESUME_PAGE_1_EXP_DURATION_${index}`];
      const position = process.env[`NEXT_PUBLIC_RESUME_PAGE_1_EXP_POSITION_${index}`];

      if (!duration || !position) {
        break;
      }

      const experience = {
        duration,
        position,
        projects: []
      };

      let projectIndex = 1; // inner loop for projects
      while (true) {
        const projectName = process.env[`NEXT_PUBLIC_RESUME_PAGE_1_EXP_PROJECT_${index}_NAME_${projectIndex}`];
        const projectDesc = process.env[`NEXT_PUBLIC_RESUME_PAGE_1_EXP_PROJECT_${index}_DESC_${projectIndex}`];

        if (!projectName || !projectDesc) {
          break;
        }

        const project = {
          name: projectName,
          description: projectDesc,
          responsibilities: []
        };

        let respIndex = 1; // inner loop for responsibilities
        while (true) {
          const responsibility = process.env[`NEXT_PUBLIC_RESUME_PAGE_1_EXP_PROJECT_${index}_DESC_${projectIndex}_RESP_${respIndex}`];

          if (!responsibility) {
            break;
          }

          project.responsibilities.push(responsibility);
          respIndex++;
        }

        experience.projects.push(project);
        projectIndex++;
      }

      experienceData.push(experience);
      index++;
    }

    return experienceData;
  })(),

  getEducationData: (() => {
    const educationData = [];
    let index = 1; // starting from 1 as per your env format

    while (true) {
      const year = process.env[`NEXT_PUBLIC_RESUME_EDUCATION_YEAR_${index}`];
      const course = process.env[`NEXT_PUBLIC_RESUME_EDUCATION_COURSE_${index}`];
      const academy = process.env[`NEXT_PUBLIC_RESUME_EDUCATION_ACADEMY_${index}`];

      if (!year || !course || !academy) {
        break;
      }

      const education = {
        year,
        course,
        academy
      };

      educationData.push(education);
      index++;
    }

    return educationData;
  })(),

  getWorkData: (() => {
    const { publicRuntimeConfig } = getConfig(); // assuming you're using next/config
    let sequence;
  
    if (process.env.NEXT_PUBLIC_WORK_SEQ) {
      sequence = process.env.NEXT_PUBLIC_WORK_SEQ.split(',').map(Number);
    }
  
    const data = [];
  
    function getWorkFromEnv(index) {
      const workData = {};
  
      for (const key of envWorkKey) {
        const envKey = `NEXT_PUBLIC_WORK_${index}_${key}`;
        workData[key.toLowerCase()] = publicRuntimeConfig[envKey];
      }
  
      // Check if all required data is available; if not, return null
      if (!workData.id) {
        return null;
      }
  
      return workData;
    }
  
    if (sequence) {
      sequence.forEach((id) => {
        const work = getWorkFromEnv(id);
        if (work) {
          data.push(work);
        }
      });
    } else {
      let index = 1;
      while (true) {
        const work = getWorkFromEnv(index);
        if (work) {
          data.push(work);
          index++;
        } else {
          break;
        }
      }
    }
  
    return data;
  })(),  

  // Footer
  linkedInUrl: process.env.NEXT_PUBLIC_LINKEDIN_URL,
  githubUrl: process.env.NEXT_PUBLIC_GITHUB_URL,
  gmailUrl: process.env.NEXT_PUBLIC_GMAIL_URL,
  githubPortfolio: process.env.NEXT_PUBLIC_GITHUB_PORTFOLIO,
  instagramUrl: process.env.NEXT_PUBLIC_INSTAGRAM_URL,

  getExperienceData: (() => {
    const { publicRuntimeConfig } = getConfig(); // assuming you're using next/config
    let sequence;

    if (process.env.NEXT_PUBLIC_EXPERIENCE_SEQ) {
      sequence = process.env.NEXT_PUBLIC_EXPERIENCE_SEQ.split(',').map(Number);
    }

    const data = [];

    function getExperienceFromEnv(index) {
      const experienceData = {};

      experienceData.title = publicRuntimeConfig[`NEXT_PUBLIC_EXPERIENCE_${index}_TITLE`];
      experienceData.description = publicRuntimeConfig[`NEXT_PUBLIC_EXPERIENCE_${index}_DESCRIPTION`];
      experienceData.image = publicRuntimeConfig[`NEXT_PUBLIC_EXPERIENCE_${index}_IMAGE`];

      // Extracting tags into an array
      const tags = publicRuntimeConfig[`NEXT_PUBLIC_EXPERIENCE_${index}_TAG`];
      if (tags) {
        // Assuming tags are stored as a string representation of an array
        try {
          experienceData.tags = JSON.parse(tags);
        } catch (e) {
          console.error('Error parsing tags for experience', index, e);
          experienceData.tags = []; // default to an empty array in case of an error
        }
      } else {
        experienceData.tags = [];
      }

      if (!experienceData.title) {
        return null;
      }

      return experienceData;
    }

    if (sequence) {
      sequence.forEach((id) => {
        const experience = getExperienceFromEnv(id);
        if (experience) {
          data.push(experience);
        }
      });
    } else {
      let index = 1;
      while (true) {
        const experience = getExperienceFromEnv(index);
        if (experience) {
          data.push(experience);
          index++;
        } else {
          break;
        }
      }
    }

    return data;
  })(),

  // About
  aboutCategory: (() => {
    try {
      // Assuming the environment variable is a string that looks like a JSON array
      const categoryStr = process.env.NEXT_PUBLIC_ABOUT_CATEGORY;
      return JSON.parse(categoryStr);
    } catch (e) {
      console.error('Error parsing about category:', e);
      return []; // Default to an empty array in case of an error
    }
  })(),
  aboutDescription: process.env.NEXT_PUBLIC_ABOUT_DESCRIPTION,
  aboutContactDescription: process.env.NEXT_PUBLIC_ABOUT_CONTACT_DESCRIPTION,
};
