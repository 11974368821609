const envTechStackKey = ["TITLE", "ID", "MAIN", "DESC", "TAG", "BG", "TEXT"];
const envWorkKey = ["ID", "IMAGE", "TITLE", "DESCRIPTION", "LINK", "TARGET"];
const envExperienceKey = ["TITLE", "DESCRIPTION", "TAG", "IMAGE"];

const filterColor = {
  red: "invert(25%) sepia(100%) saturate(7482%) hue-rotate(2deg) brightness(90%) contrast(86%)",
  green: "invert(84%) sepia(91%) saturate(4213%) hue-rotate(87deg) brightness(97%) contrast(91%)",
  blue: "invert(98%) sepia(99%) saturate(4229%) hue-rotate(196deg) brightness(101%) contrast(103%)",
  yellow: "invert(97%) sepia(100%) saturate(2218%) hue-rotate(19deg) brightness(93%) contrast(90%)",
  orange: "invert(75%) sepia(100%) saturate(3928%) hue-rotate(7deg) brightness(93%) contrast(91%)",
  purple: "invert(47%) sepia(100%) saturate(2770%) hue-rotate(257deg) brightness(92%) contrast(91%)",
  cyan: "invert(91%) sepia(16%) saturate(4320%) hue-rotate(165deg) brightness(95%) contrast(91%)",
  magenta: "invert(55%) sepia(81%) saturate(5391%) hue-rotate(302deg) brightness(90%) contrast(86%)",
  black: "invert(13%) sepia(70%) saturate(4186%) hue-rotate(0deg) brightness(20%) contrast(139%)",
  white: "invert(100%) brightness(200%)",
  sky: "invert(43%) sepia(25%) saturate(4001%) hue-rotate(203deg) brightness(103%) contrast(93%)",
  lightSky: "invert(43%) sepia(87%) saturate(1486%) hue-rotate(188deg) brightness(101%) contrast(102%)",
  pink: "invert(60%) sepia(13%) saturate(4654%) hue-rotate(294deg) brightness(88%) contrast(77%)",
  gray: "invert(41%) sepia(8%) saturate(690%) hue-rotate(185deg) brightness(94%) contrast(95%)",
};

module.exports = {
  envTechStackKey,
  envWorkKey,
  filterColor,
  envExperienceKey,
};
